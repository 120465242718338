import React from 'react';
import Layout from '../components/layout';
import {Container, Row, Col, Collapse} from 'reactstrap';

class NotFound extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <div className="faq">
            <h1>Sorry!</h1>
            <h2>This page doesn't seem to exist</h2>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default NotFound;
